import './EventDetails.scss'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { injectIntl } from 'react-intl'
import {
  mapKeywordSetToForm,
  mapLanguagesSetToForm,
} from '../../utils/apiDataMapping'
import { LinksToEvents } from '../LinksToEvents/LinksToEvents'
import {
  DateTime,
  ImageValue,
  MultiLanguageHtmlValue,
  MultiLanguageValue,
  OffersValue,
  OptionGroup,
  TextValue,
} from './Fields'
import { Topics } from './Topics'

const EventDetailsComponent = (props) => {
  const {
    editor,
    values,
    intl,
    rawData,
    publisher,
    superEvent,
    // TODO: With hooks, you can use const locale = useSelector(state => state.userLocale.locale)
    userLocale: { locale },
  } = props
  const placeKeywords = mapKeywordSetToForm(
    editor.keywordSets,
    'espoo:places',
    locale
  )
    .filter((placeKeyword) => values.placeKeywords.includes(placeKeyword.value))
    .map((placeKeyword) => placeKeyword.label)
  const targetGroups = mapKeywordSetToForm(
    editor.keywordSets,
    'espoo:audiences',
    locale
  )
    .filter(
      (targetGroupKeyword) =>
        values.audience && values.audience.includes(targetGroupKeyword.value)
    )
    .map((targetGroupKeyword) => targetGroupKeyword.label)
  const eventLanguages = mapLanguagesSetToForm(editor.languages, locale)
    .filter(
      (eventLanguage) =>
        values.in_language && values.in_language.includes(eventLanguage.value)
    )
    .map((eventLanguage) => eventLanguage.label)

  return (
    <div className="event-details">
      <ImageValue labelKey="event-image" value={values.image} />
      <legend>
        {intl.formatMessage({ id: 'event-description-fields-header' })}
      </legend>

      <MultiLanguageValue labelKey="event-headline" value={values.name} />
      <MultiLanguageValue
        labelKey="event-short-description"
        value={values.short_description}
      />
      <MultiLanguageHtmlValue
        labelKey="event-description"
        value={values.description_html}
      />
      <MultiLanguageValue labelKey="event-info-url" value={values.info_url} />
      <MultiLanguageValue labelKey="event-provider" value={values.provider} />
      {publisher && (
        <TextValue labelKey="event-publisher" value={get(publisher, 'name')} />
      )}

      <legend>
        {intl.formatMessage({ id: 'event-datetime-fields-header' })}
      </legend>
      {values.date_published && (
        <DateTime
          value={values.date_published}
          labelKey="event-published-datetime"
        />
      )}
      <DateTime value={values.start_time} labelKey="event-starting-datetime" />
      <DateTime value={values.end_time} labelKey="event-ending-datetime" />

      <legend>
        {intl.formatMessage({ id: 'event-location-fields-header' })}
      </legend>

      <MultiLanguageValue
        labelKey="event-location"
        value={get(values, 'location.name')}
      />
      <TextValue
        labelKey="event-location-id"
        value={get(values, 'location.id')}
      />
      <MultiLanguageValue
        labelKey="event-location-additional-info"
        value={values.location_extra_info}
      />

      <legend>{intl.formatMessage({ id: 'event-price-fields-header' })}</legend>
      <OffersValue locale={locale} values={values} />

      <TextValue
        labelKey="kaikukortti"
        value={
          values.kaikukortti
            ? intl.formatMessage({ id: 'access-with-kaikukortti' })
            : intl.formatMessage({ id: 'no-access-with-kaikukortti' })
        }
      />
      <TextValue
        labelKey="museokortti"
        value={
          values.museokortti
            ? intl.formatMessage({ id: 'access-with-museokortti' })
            : intl.formatMessage({ id: 'no-access-with-museokortti' })
        }
      />

      <TextValue
        labelKey="event-registration-link"
        value={values.event_registration_link}
      />

      <legend>
        {intl.formatMessage({ id: 'event-social-media-fields-header' })}
      </legend>
      <TextValue labelKey="facebook-url" value={values.extlink_facebook} />
      <TextValue labelKey="twitter-url" value={values.extlink_twitter} />
      <TextValue labelKey="instagram-url" value={values.extlink_instagram} />

      <legend>{intl.formatMessage({ id: 'event-categorization' })}</legend>

      <Topics values={values} />
      <OptionGroup values={placeKeywords} labelKey="place-keywords" />
      <OptionGroup values={targetGroups} labelKey="target-groups" />
      <OptionGroup values={eventLanguages} labelKey="event-languages" />

      <legend>{intl.formatMessage({ id: 'audience-age-restrictions' })}</legend>
      {values?.no_age_limit ? (
        <p> {intl.formatMessage({ id: 'no-age-limit' })} </p>
      ) : (
        <div>
          <TextValue
            labelKey="audience-min-age"
            value={values.audience_min_age?.toString()}
          />
          <TextValue
            labelKey="audience-max-age"
            value={values.audience_max_age?.toString()}
          />{' '}
        </div>
      )}

      <legend>{intl.formatMessage({ id: 'links-to-events' })}</legend>
      <LinksToEvents event={rawData} superEvent={superEvent} />
    </div>
  )
}

EventDetailsComponent.propTypes = {
  values: PropTypes.object,
  superEvent: PropTypes.object,
  rawData: PropTypes.object,
  intl: PropTypes.object,
  publisher: PropTypes.object,
  editor: PropTypes.object,
  userLocale: PropTypes.object,
}

const mapStateToProps = ({ userLocale }) => ({
  userLocale,
})

export const ConnectedEventDetails = injectIntl(
  connect(mapStateToProps)(EventDetailsComponent)
)
