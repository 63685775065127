import './FormFields.scss'

import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import CopyToClipboard from 'react-copy-to-clipboard'
import { TextField } from '@material-ui/core'
import { FileCopyOutlined } from '@material-ui/icons'
import { get, isNull } from 'lodash'
import { isHobby } from '../../validation/validationRules'
import { ConnectedImagePicker } from '../ImagePicker/ImagePicker'
import {
  MultiLanguageField,
  HelTextField,
  HelLabeledCheckboxGroup,
  ConnectedHelSelect,
  HelOffersField,
  HelCheckbox,
} from '../HelFormFields'
import {
  mapKeywordSetToForm,
  mapLanguagesSetToForm,
} from '../../utils/apiDataMapping'
import { setData } from '../../actions/editor'
import { constants } from '../../constants'
import { OrganizationSelector } from '../HelFormFields/OrganizationSelector'
import { UmbrellaSelector } from '../HelFormFields/UmbrellaSelector/NewUmbrellaSelector'
import { SideField } from './SideField'
import { setPlaceKeywordsBasedOnLocation } from '../../utils/placeKeywords'
import { HtmlDescription } from './HtmlDescription'
import { Topics } from './Topics/Topics'
import { PresentedLanguages } from './PresentedLanguages'
import { EventTime } from './EventTime/EventTime'

const FormHeader = (props) => (
  <div className="row">
    <legend className="col-sm-12">{props.children}</legend>
  </div>
)

FormHeader.propTypes = {
  children: PropTypes.element,
}

export class FormFields extends React.Component {
  componentDidMount() {
    const { action } = this.props

    // set default value for organization if user is creating new event
    if (action === 'create') {
      this.setDefaultOrganization()
    }
  }

  UNSAFE_componentWillReceiveProps() {
    this.forceUpdate()
  }

  shouldComponentUpdate() {
    return true
  }

  setDefaultOrganization = () => {
    const { user } = this.props

    if (isNull(user)) {
      return
    }

    const userType = get(user, 'userType')
    const defaultOrganizationData = get(
      user,
      [`${userType}OrganizationData`, `${user.organization}`],
      {}
    )

    this.context.dispatch(setData({ organization: defaultOrganizationData.id }))
  }

  trimmedDescription() {
    const descriptions = {
      ...this.props.editor.values.description,
    }

    for (const lang in descriptions) {
      if (descriptions[lang] !== null) {
        descriptions[lang] = descriptions[lang]
          .replace(/<\/p><p>/gi, '\n\n')
          .replace(/<br\s*[/]?>/gi, '\n')
          .replace(/<p>/g, '')
          .replace(/<\/p>/g, '')
          .replace(/&amp;/g, '&')
      }
    }
    return descriptions
  }

  render() {
    const {
      // TODO: With hooks, you can use const locale = useSelector(state => state.userLocale.locale)
      superEvent,
      user,
      editor,
      userLocale,
    } = this.props
    const placeKeywordOptions = mapKeywordSetToForm(
      editor.keywordSets,
      'espoo:places',
      userLocale.locale
    )
    const targetGroupOptions = mapKeywordSetToForm(
      editor.keywordSets,
      'espoo:audiences',
      userLocale.locale
    )
    const eventLangOptions = mapLanguagesSetToForm(
      editor.languages,
      userLocale.locale
    )

    const { values, validationErrors, contentLanguages } = editor
    const { VALIDATION_RULES } = constants

    const userType = get(user, 'userType')
    const organizationData = get(user, `${userType}OrganizationData`, {})
    const publisherOptions = Object.keys(organizationData).map((id) => ({
      label: organizationData[id].name,
      value: id,
    }))

    const selectedPublisher =
      publisherOptions.find((option) => option.value === values.organization) ||
      {}

    /**
     * A callback which is called when the location field changes.
     *
     * @param {object} selectedValue - The selected location in HelSelect
     * @param {string[]} currentPlaceKeywords - The current place keywords stored in the state
     * @param {object[]} keywordSets - The keyword sets stored in the state
     */
    const onLocationChange = (
      selectedValue,
      currentPlaceKeywords,
      keywordSets
    ) => {
      const location = selectedValue
      const placeKeywords = setPlaceKeywordsBasedOnLocation(
        location,
        currentPlaceKeywords,
        keywordSets
      )
      this.context.dispatch(setData({ placeKeywords }))
    }

    return (
      <div>
        <PresentedLanguages contentLanguages={contentLanguages} />

        <FormHeader>
          <FormattedMessage id="event-description-fields-header" />
        </FormHeader>

        <div className="row">
          <div className="col-sm-6">
            <MultiLanguageField
              required={true}
              multiLine={false}
              label="event-headline"
              name="name"
              validationErrors={validationErrors.name}
              defaultValue={values.name}
              languages={this.props.editor.contentLanguages}
            />

            <MultiLanguageField
              required={true}
              multiLine={true}
              label="event-short-description"
              name="short_description"
              validationErrors={validationErrors.short_description}
              defaultValue={values.short_description}
              languages={this.props.editor.contentLanguages}
              validations={[VALIDATION_RULES.SHORT_STRING]}
              forceApplyToStore
            />
            <HtmlDescription
              validationErrors={validationErrors.description_html}
            />
            <MultiLanguageField
              required={false}
              multiLine={false}
              label="event-info-url"
              name="info_url"
              validationErrors={validationErrors.info_url}
              defaultValue={values.info_url}
              languages={this.props.editor.contentLanguages}
              validations={[VALIDATION_RULES.IS_URL]}
              forceApplyToStore
            />
            <MultiLanguageField
              required={false}
              multiLine={false}
              label="event-provider-input"
              name="provider"
              validationErrors={validationErrors.provider}
              defaultValue={values.provider}
              languages={this.props.editor.contentLanguages}
            />
            <OrganizationSelector
              options={publisherOptions}
              selectedOption={selectedPublisher}
              onChange={(option) =>
                option &&
                this.context.dispatch(setData({ organization: option.value }))
              }
            />
          </div>
          <SideField>
            <label>
              <FormattedMessage id="event-image" />
            </label>
            <ConnectedImagePicker
              label="image-preview"
              name="image"
              loading={this.props.loading}
            />
          </SideField>
        </div>

        <FormHeader>
          <FormattedMessage id="event-datetime-fields-header" />
        </FormHeader>
        <EventTime loading={this.props.loading} />

        <FormHeader>
          <FormattedMessage id="event-location-fields-header" />
        </FormHeader>
        <div className="row location-row">
          <div className="col-sm-6 hel-select" data-testid="location-container">
            <ConnectedHelSelect
              required={true}
              selectedValue={values.location}
              legend={this.context.intl.formatMessage({ id: 'event-location' })}
              name="location"
              data-testid="location-selector"
              resource="place"
              validationErrors={validationErrors.location}
              onChangeCallback={(selectedValue) =>
                onLocationChange(
                  selectedValue,
                  values.placeKeywords,
                  editor.keywordSets
                )
              }
            />
            <TextField
              fullWidth
              disabled
              label={this.context.intl.formatMessage({
                id: 'event-location-id',
              })}
              value={
                values.location && values.location.id ? values.location.id : ''
              }
            />
            <CopyToClipboard text={values.location ? values.location.id : ''}>
              <button
                className="clipboard-copy-button"
                title={this.context.intl.formatMessage({
                  id: 'copy-to-clipboard',
                })}
              >
                <FileCopyOutlined />
              </button>
            </CopyToClipboard>
            <MultiLanguageField
              multiLine={true}
              label="event-location-additional-info"
              name="location_extra_info"
              validationErrors={validationErrors.location_extra_info}
              validations={[VALIDATION_RULES.SHORT_STRING]}
              defaultValue={values.location_extra_info}
              languages={this.props.editor.contentLanguages}
            />
          </div>
          <SideField>
            <div className="tip">
              <p>
                <FormattedMessage id="editor-tip-location" />
              </p>
              <p>
                <strong>
                  <FormattedMessage id="editor-tip-location-internet" />
                </strong>
              </p>
              <p>
                <FormattedMessage id="editor-tip-location-extra" />
              </p>
              <p>
                <FormattedMessage id="editor-tip-location-not-found" />
              </p>
            </div>
          </SideField>
        </div>

        <FormHeader>
          <FormattedMessage id="event-price-fields-header" />
        </FormHeader>
        <div className="row">
          <div className="col-sm-6">
            <HelTextField
              validations={[VALIDATION_RULES.IS_URL]}
              name="event_registration_link"
              validationErrors={validationErrors.event_registration_link}
              label={<FormattedMessage id="event-registration-link" />}
              defaultValue={values.event_registration_link}
              forceApplyToStore
            />
            <HelOffersField
              name="offers"
              validationErrors={validationErrors}
              defaultValue={values.offers}
              museokortti={values.museokortti}
              kaikukortti={values.kaikukortti}
              languages={this.props.editor.contentLanguages}
            />
          </div>
          <SideField>
            <div className="tip">
              <p>
                <FormattedMessage id="editor-tip-price-info" />
              </p>
              <p>
                <FormattedMessage id="editor-tip-price-field" />
              </p>
              <p>
                <FormattedMessage id="editor-tip-price-description-field" />
              </p>
            </div>
          </SideField>
        </div>

        <FormHeader>
          <FormattedMessage id="event-social-media-fields-header" />
        </FormHeader>
        <div className="row">
          <div className="col-sm-6">
            <HelTextField
              validations={[VALIDATION_RULES.IS_URL]}
              name="extlink_facebook"
              label={<FormattedMessage id="facebook-url" />}
              validationErrors={validationErrors.extlink_facebook}
              defaultValue={values.extlink_facebook}
              forceApplyToStore
            />
            <HelTextField
              validations={[VALIDATION_RULES.IS_URL]}
              name="extlink_twitter"
              label={<FormattedMessage id="twitter-url" />}
              validationErrors={validationErrors.extlink_twitter}
              defaultValue={values.extlink_twitter}
              forceApplyToStore
            />
            <HelTextField
              validations={[VALIDATION_RULES.IS_URL]}
              name="extlink_instagram"
              label={<FormattedMessage id="instagram-url" />}
              validationErrors={validationErrors.extlink_instagram}
              defaultValue={values.extlink_instagram}
              forceApplyToStore
            />
          </div>
          <SideField>
            <p className="tip">
              <FormattedMessage id="editor-tip-social-media" />
            </p>
          </SideField>
        </div>

        <FormHeader>
          <FormattedMessage id="event-categorization" />
        </FormHeader>
        <Topics />
        <div className="row">
          <HelLabeledCheckboxGroup
            required={true}
            groupLabel={<FormattedMessage id="place-keywords" />}
            selectedValues={values.placeKeywords}
            validationErrors={validationErrors.placeKeywords}
            itemClassName="col-md-12 col-lg-6"
            options={placeKeywordOptions}
            handleChange={(placeKeywords) =>
              this.context.dispatch(setData({ placeKeywords }))
            }
          />
          <SideField>
            <p className="tip">
              <FormattedMessage id="editor-tip-place-keywords" />
            </p>
          </SideField>
        </div>

        <div className="row">
          <HelLabeledCheckboxGroup
            groupLabel={<FormattedMessage id="event-languages" />}
            selectedValues={values.in_language}
            validationErrors={validationErrors.in_language}
            itemClassName="col-md-12 col-lg-6"
            options={eventLangOptions}
            handleChange={(in_language) =>
              this.context.dispatch(setData({ in_language }))
            }
          />
          <SideField>
            <p className="tip">
              <FormattedMessage id="editor-tip-event-languages" />
            </p>
          </SideField>
        </div>

        <div>
          <FormHeader>
            <FormattedMessage id="audience-age-restrictions" />
          </FormHeader>
          <div className="row" style={{ justifyContent: 'space-between' }}>
            <div className="col-xs-12 col-sm-6">
              <HelCheckbox
                name="no_age_limit"
                label={<FormattedMessage id="no-age-limit" />}
                disabled={isHobby(values)}
                defaultChecked={values.no_age_limit}
              />
              {!values.no_age_limit && (
                <div>
                  <HelTextField
                    name="audience_min_age"
                    required={isHobby(values)}
                    label={<FormattedMessage id="audience-min-age" />}
                    validationErrors={validationErrors.audience_min_age}
                    defaultValue={values.audience_min_age?.toString()}
                  />

                  <HelTextField
                    name="audience_max_age"
                    required={isHobby(values)}
                    label={<FormattedMessage id="audience-max-age" />}
                    validationErrors={validationErrors.audience_max_age}
                    defaultValue={values.audience_max_age?.toString()}
                  />
                </div>
              )}
            </div>
            <SideField>
              <div className="tip">
                <p>
                  <FormattedMessage id="editor-tip-age-restriction" />
                </p>
                <p>
                  <FormattedMessage id="editor-tip-age-restriction-upper-and-lower" />
                </p>
                <p>
                  <FormattedMessage id="editor-tip-age-restriction-lower" />
                </p>
                <p>
                  <FormattedMessage id="editor-tip-age-restriction-upper" />
                </p>
              </div>
            </SideField>
          </div>
        </div>

        <div className="row">
          <HelLabeledCheckboxGroup
            groupLabel={<FormattedMessage id="target-groups" />}
            selectedValues={values.audience}
            validationErrors={validationErrors.audience}
            itemClassName="col-md-12 col-lg-6"
            options={targetGroupOptions}
            handleChange={(audience) =>
              this.context.dispatch(setData({ audience }))
            }
          />
          <SideField>
            <p className="tip">
              <FormattedMessage id="editor-tip-target-group" />
            </p>
          </SideField>
        </div>

        {!this.props.loading && (
          <>
            <FormHeader>
              <FormattedMessage id="event-umbrella" />
            </FormHeader>
            <div className="row">
              <div className="umbrella-event-checkboxes">
                <UmbrellaSelector
                  updateExisting={this.props.action === 'update'}
                  isOldSubEvent={!!this.props.superEvent}
                  superEvent={superEvent}
                />
              </div>
              <SideField>
                <div className="tip">
                  <p>
                    <FormattedMessage id="editor-tip-long-umbrella-events" />
                  </p>
                  <p>
                    <FormattedMessage id="editor-tip-umbrella-events" />
                  </p>
                </div>
              </SideField>
            </div>
          </>
        )}
      </div>
    )
  }
}

FormFields.propTypes = {
  intl: PropTypes.object,
  dispatch: PropTypes.func,
  editor: PropTypes.object,
  event: PropTypes.object,
  superEvent: PropTypes.object,
  user: PropTypes.object,
  action: PropTypes.oneOf(['update', 'create']),
  loading: PropTypes.bool,
  userLocale: PropTypes.object,
}

FormFields.contextTypes = {
  intl: PropTypes.object,
  dispatch: PropTypes.func,
}
