import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { update as oldEditor } from '../../reducers/editor'
import { Language, WrappedId } from '../../types/apiTypes'
import {
  EditorMode,
  EditorState,
  EditorValues,
  ValidationErrors,
} from './types'
import { getYsoFromId } from '../../utils/formDataMapping'

const initialState: EditorState = {
  values: {
    description_html: {
      fi: null,
      sv: null,
      en: null,
    },
    metaKeywords: [],
    name: '',
    keywords: [],
    hobbyCategories: [],
    libraryKeywords: [],
    in_language: [],
    sub_events: {},
    no_age_limit: false,
  },
  languages: {},
  old_subevent_ids: [],
  contentLanguages: ['fi'],
  keywordSets: [],
  validationErrors: {},
  validateFor: null,
  loading: false,
  serverErrorData: {},
  superEventOrg: '',
}

// utils
/**
 * Deletes a single event from values - object.
 * @param values - A reference to the values object that is modified.
 * @param id - key of the sub event.
 */
const deleteSingleSubEvent = (
  values: EditorValues,
  event: EditorValues,
  id: number,
  totalDelete = true
) => {
  if (!values.sub_events?.[id]) {
    return
  }

  if (event?.['@id'] && totalDelete) {
    // sub event is already saved in the backend.
    // mark it as deleted.
    values.sub_events[id].markAsDeleted = true
  } else {
    // sub event is not saved in the backend.
    // just remove it from the editor.
    delete values.sub_events[id]
  }
}

export const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    editorModeSet(
      state: EditorState,
      action: PayloadAction<{ mode: EditorMode | undefined }>
    ) {
      const { mode } = action.payload
      state.mode = mode
    },

    setHtmlDescription(
      state: EditorState,
      action: PayloadAction<{ lang: Language; text: string }>
    ) {
      const { lang, text } = action.payload
      state.values.description_html = {
        ...state.values.description_html,
        [lang]: text,
      }
    },

    setSuperEvent(
      state: EditorState,
      action: PayloadAction<{
        superEventId: WrappedId
      }>
    ) {
      state.values.super_event = action.payload.superEventId
    },

    setSuperEventType(
      state: EditorState,
      action: PayloadAction<{
        newType: 'recurring' | 'umbrella' | undefined
      }>
    ) {
      state.values.super_event_type = action.payload.newType
    },

    setSuperEventOrg(state: EditorState, action: PayloadAction<string>) {
      state.superEventOrg = action.payload
    },

    addSubEvent(
      state: EditorState,
      action: PayloadAction<{
        event: EditorValues
      }>
    ) {
      const numberOfOldSubevents =
        state.values.sub_events != null
          ? Object.keys(state.values.sub_events).length
          : 0
      state.values.sub_events = {
        ...state.values.sub_events,
        [numberOfOldSubevents]: action.payload.event,
      }
    },

    setOldSubevents(
      state: EditorState,
      action: PayloadAction<{
        ids: string[]
      }>
    ) {
      state.old_subevent_ids = action.payload.ids
    },

    /**
     * Delete sub event from redux store,
     * or mark it as deleted if it's already saved in the backend.
     */
    deleteSubEvent(
      state: EditorState,
      action: PayloadAction<{
        event: EditorValues
        subEventKey: number
        totalDelete?: boolean
      }>
    ) {
      deleteSingleSubEvent(
        state.values,
        action.payload.event,
        action.payload.subEventKey,
        action.payload.totalDelete ?? true
      )
    },

    deleteAllSubEvents(
      state: EditorState,
      action: PayloadAction<EditorValues>
    ) {
      if (!action.payload || !action.payload.sub_events) {
        return
      }

      for (const key of Object.keys(action.payload.sub_events)) {
        deleteSingleSubEvent(
          state.values,
          action.payload.sub_events[+key],
          +key
        )
      }
    },

    setLoading(state: EditorState, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },

    setMetaKeywords(state: EditorState, action: PayloadAction<string[]>) {
      state.values.metaKeywords = action.payload
      if (state.values.metaKeywords.map(getYsoFromId).includes('yso:p2901')) {
        state.values.no_age_limit = false
      }
    },

    setKortti(
      state: EditorState,
      action: PayloadAction<{
        kortti: 'kaiku' | 'museo'
        state: boolean
      }>
    ) {
      if (action.payload.kortti === 'kaiku') {
        state.values.kaikukortti = action.payload.state
      } else if (action.payload.kortti === 'museo') {
        state.values.museokortti = action.payload.state
      }
    },
  },
})

// Disabling these warnings is okay while refactoring from old editor reducer to the new one
// Once it's done, these warnings should be taken care of if still present
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const combinedEditor = (state: EditorState, action: any): EditorState => {
  const intermediateState = oldEditor(state, action)
  const reducer = editorSlice.reducer(intermediateState, action)
  return reducer
}

// exports

export const {
  editorModeSet,
  setHtmlDescription,
  setSuperEvent,
  setSuperEventOrg,
  setSuperEventType,
  setOldSubevents,
  addSubEvent,
  deleteSubEvent,
  deleteAllSubEvents,
  setLoading,
  setMetaKeywords,
  setKortti,
} = editorSlice.actions

export { EditorMode, EditorValues, ValidationErrors, EditorState }

export default combinedEditor
