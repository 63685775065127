import React, { useEffect, useRef, useState } from 'react'
import OrganizationAccessRequestForm from './OrganizationAccessRequestForm'
import { useIntl } from 'react-intl'
import { useClient } from '../../api/clientContext'

import './UserInfoPage.scss'
import {
  OrganizationAccessRequest,
  mapResponseDataToOAR,
} from '../../types/apiTypes'
import UserOrganizations from './UserOrganizations'
import BasicUserInfo from './BasicUserInfo'
import { RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { getRest } from '../UserManagement/UserManagement'
import { constants } from '../../constants'
import { setLoginModal } from '../../store/app'
import { features } from '../../utils/featureFlags'
import { getAdLoginUrl } from '../../utils/user'

export type UserViewInformation = {
  displayName: string
  adminOrganizations: string[]
  organizationMemberships: string[]
}

export type OrganizationSelect = {
  label: string
  id: string
  parent: string
}

const UserInfoPage: React.FC = () => {
  const client = useClient()
  const intl = useIntl()
  const dispatch = useDispatch()
  // TODO: Should you use redux editor/setLoading instead?
  const [loading, setLoading] = useState(true)
  const [organizations, setOrganizations] = useState<OrganizationSelect[]>([])
  const [organizationAccessRequests, setOrganizationAccessRequests] = useState<
    OrganizationAccessRequest[]
  >([])
  const [refreshing, setRefreshing] = useState(false)
  const userViewInfo = useSelector((state: RootState) => state.user)

  useEffect(() => {
    if (!client || !userViewInfo) {
      return
    }

    const fetchOrganizations = async (): Promise<void> => {
      const firstResponse = await client.get('organization', {
        params: { page_size: constants.pageSize },
      })
      const restResponse = await Promise.all(
        getRest(client, 'organization', firstResponse.data.meta.count)
      )
      const responseData = [
        ...firstResponse.data.data,
        ...restResponse.flatMap((response) => response.data.data),
      ]

      setOrganizations(
        responseData.map((org: Record<string, string>) => ({
          label: org.name,
          id: org.id,
          parent: org.parent_organization,
        }))
      )
    }

    const fetchOrganizationAccessRequests = async (): Promise<void> => {
      const response = await client.get('organizationaccessrequest', {
        params: { include: 'organization', page_size: constants.pageSize },
      })
      const OARs: OrganizationAccessRequest[] =
        response.data?.data?.map(mapResponseDataToOAR) ?? []

      // TODO: Figure out a better way to filter user's own OARs
      const usersOwnOARs = OARs.filter(
        (oar) => userViewInfo?.id === oar.requester?.uuid
      )

      setOrganizationAccessRequests(usersOwnOARs)
    }

    Promise.all([fetchOrganizations(), fetchOrganizationAccessRequests()]).then(
      () => {
        setLoading(false)
        setRefreshing(false)
      }
    )
  }, [client, refreshing, userViewInfo])

  if (!userViewInfo) {
    return (
      <div className="container">
        <h1>{intl.formatMessage({ id: 'user-info-page' })}</h1>
        <p>
          {features.multiLogin ? (
            <a
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={() => dispatch(setLoginModal(true))}
            >
              {intl.formatMessage({ id: 'login' })}
            </a>
          ) : (
            <a
              style={{ cursor: 'pointer', color: 'blue' }}
              href={getAdLoginUrl()}
            >
              {intl.formatMessage({ id: 'login' })}
            </a>
          )}
          {` ${intl.formatMessage({ id: 'user-info-page-prompt' })}`}
        </p>
      </div>
    )
  }

  return (
    <div className="container">
      <h2>{intl.formatMessage({ id: 'user-info-page' })}</h2>
      <BasicUserInfo loading={loading} userViewInfo={userViewInfo} />
      <h2>{intl.formatMessage({ id: 'users-organizations' })}</h2>
      <UserOrganizations
        loading={loading}
        userViewInfo={userViewInfo}
        organizations={organizations}
        organizationAccessRequests={organizationAccessRequests}
        setRefreshing={setRefreshing}
      />
      <h2>{intl.formatMessage({ id: 'new-organization-access-request' })}</h2>
      <OrganizationAccessRequestForm
        userOrganizations={userViewInfo?.adminOrganizations || []}
        organizations={organizations.filter((org) =>
          org.parent?.includes('espoo:external')
        )}
        organizationAccessRequests={organizationAccessRequests}
        setRefreshing={setRefreshing}
      />
    </div>
  )
}

export default UserInfoPage
